import React, {useEffect} from "react";
import {Form, Icon} from "antd";
import { FormComponentProps } from "antd/lib/form";
import ButtonSearch from "../../components/common/form/button/ButtonSearch";
import InputWithLabel from "../../components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import {STATUS_OPTIONS} from "./enum/RequestRenewAndWarrantyEnum";

import { Menu, Dropdown, Button } from 'antd';
import { checkPermission } from "helpers/NewCaCrmHelper";
import { REQUEST_RENEW_AND_WARRANTY_CERT } from "helpers/enum/PermissionEnums";

interface Props extends FormComponentProps {
    history: any;
}

const RequestRenewAndWarrantySearchForm: React.FC<Props> = props => {
    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    const menuRenew = () => (
        <Menu
            onClick={(e) => handleMenuReNewClick(e)}
        >
            <Menu.Item key="renew_personal">
                <Icon type="user" />
                Cá nhân
            </Menu.Item>
            <Menu.Item key="renew_organization" >
                <Icon type="usergroup-add" />
                Tổ chức
            </Menu.Item>
        </Menu>
    );
    const menuWarranty = () => (
        <Menu
            onClick={(e) => handleMenuWarrantyClick(e)}
        >
            <Menu.Item key="warranty_personal">
                <Icon type="user" />
                Cá nhân
            </Menu.Item>
            <Menu.Item key="warranty_organization" >
                <Icon type="usergroup-add" />
                Tổ chức
            </Menu.Item>
        </Menu>
    );
    const handleMenuReNewClick = (e) =>{
        if (e.key === 'renew_personal'){
            props.history.push(`/them-moi-yeu-cau-cap-lai-thiet-bi-ca-nhan`)
        }
        if(e.key === 'renew_organization'){
            props.history.push(`/them-moi-yeu-cau-cap-lai-thiet-bi-to-chuc`)
        }
    };
    const handleMenuWarrantyClick = (e) =>{
        if (e.key === 'warranty_personal'){
            props.history.push(`/them-moi-yeu-cau-bao-hanh-thiet-bi-ca-nhan`)
        }
        if(e.key === 'warranty_organization'){
            props.history.push(`/them-moi-yeu-cau-bao-hanh-thiet-bi-to-chuc`)
        }
    };

    return (
        <Form>
            <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="identity"
                    placeholder={"Mã định danh"}
                    wrapClass="col-md-2 pl-0"
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="fullname"
                    placeholder={"Tên khách hàng"}
                    wrapClass="col-md-2 pl-0"
                />
                <SelectWithLabel
                    options={STATUS_OPTIONS}
                    name="status"
                    wrappedClass="col-md-2 pl-0"
                    form={props.form}
                    placeholder="Chọn trạng thái"
                />
                <div className="form-group mr-2 mt-1 pl-0">
                    <ButtonSearch data={props.form.getFieldsValue()} />
                </div>
                {
                    checkPermission(REQUEST_RENEW_AND_WARRANTY_CERT.ADD) 
                    && <div className="form-group mt-1 mr-2">
                    <Dropdown
                        className={"bg-success text-white"}
                        overlay={menuRenew}
                        placement="bottomLeft"
                    >
                        <Button>
                            <i className="fas fa-plus mr-1"/>
                            Thêm mới yêu cầu cấp lại
                        </Button>
                    </Dropdown>
                </div>
                }
                
                {
                    checkPermission(REQUEST_RENEW_AND_WARRANTY_CERT.ADD) 
                    && <div className="form-group mt-1 mr-2">
                    <Dropdown
                        className={"bg-success text-white"}
                        overlay={menuWarranty}
                        placement="bottomLeft"
                    >
                        <Button>
                            <i className="fas fa-plus mr-1"/>
                            Thêm mới yêu cầu bảo hành
                        </Button>
                    </Dropdown>
                </div>

                }
                
            </div>
        </Form>
    );
};

const WrappedSearchForm = Form.create<Props>({
    name: "WrappedSearchForm"
})(RequestRenewAndWarrantySearchForm);

export default WrappedSearchForm;
